import styled from "styled-components";
import { Breakpoints } from "../../constants/constants";
import getBackgroundColorMobileStyles from "./utils/getBackgroundColorMobileStyles";
import getBackgroundColorDesktopStyles from "./utils/getBackGroundColorDesktopStyles";

export const BgColorContainer = styled.div`
  background-color: ${(props) => props.backgroundColor};
`;

export const BgColorSpacingContainer = styled.div`
  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    padding-top: ${(props) => props.topPaddingMobile}px;
  }

  @media screen and (min-width: ${Breakpoints.IPad}) and (max-width: ${Breakpoints.IPhone11ProMax}) {
    padding-top: ${(props) => props.topPaddingTablet}px;
  }

  @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
    padding-top: ${(props) => props.topPaddingDesktop}px;
  }

  ${({ sizeDesktop }) => getBackgroundColorDesktopStyles(sizeDesktop)}

  // Mobile Styles
  ${({ sizeMobile }) => getBackgroundColorMobileStyles(sizeMobile)}
`;
