import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import AccordionItem from "./AccordionItem";
import { AccordionWrapper, Img } from "./AccordionStyled";
import defaultTheme from "./defaultTheme";
import useComponentTheme from "../Theme/useComponentTheme";

const Accordion = ({
  accordionItems,
  setTabIdx,
  theme = defaultTheme,
  scrollOnExpand = true,
  ...themeOverrides
}) => {
  const getAccordionInitialState = useMemo(() => {
    return (
      accordionItems
        .flatMap((item, idx) => {
          if (item.toggledByDefault) {
            return item.accordionId || idx;
          }
        })
        .filter((item) => item || item === 0) || []
    );
  }, [accordionItems]);

  const [openItems, setOpenItems] = useState(
    getAccordionInitialState && getAccordionInitialState.length > 0
      ? getAccordionInitialState
      : [0]
  );

  const toggleItem = (idx, item) => {
    const toggle = (id, items) =>
      items.includes(id) ? items.filter((item) => item !== id) : [...items, id];
    const id = item?.accordionId || idx;
    setOpenItems((prevOpenItems) => toggle(id, prevOpenItems));
  };

  const handleIsAccordionOpen = (index, item) => {
    return item?.accordionId
      ? openItems.includes(item?.accordionId)
      : openItems.includes(index);
  };

  const accordionTheme = useComponentTheme({ theme, themeOverrides });
  return (
    <ThemeProvider theme={accordionTheme}>
      <AccordionWrapper>
        {accordionItems?.map((accordionItem, idx) => {
          const key = `accordion-item-${idx}`;
          const isOpen = handleIsAccordionOpen(idx, accordionItem);
          return (
            <AccordionItem
              key={key}
              {...accordionItem}
              isOpen={isOpen}
              toggleItem={() => toggleItem(idx, accordionItem)}
              setTabIdx={setTabIdx}
              scrollOnExpand={scrollOnExpand}
            />
          );
        })}
        <Img onClick={() => null} />
      </AccordionWrapper>
    </ThemeProvider>
  );
};

export default Accordion;

Accordion.propTypes = {
  accordionItems: PropTypes.array,
  theme: PropTypes.object,
  setTabIdx: PropTypes.func
};
