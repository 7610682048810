import React from "react";
import PropTypes from "prop-types";
import {
  BgColorContainer,
  BgColorSpacingContainer
} from "./BackgroundColorStyled";
import BackgroundColorContent from "./BackgroundColorContent";

const BackgroundColor = ({
  contentItems,
  contentItemIndex,
  backgroundColorProps
}) => {
  return (
    <div>
      {contentItems &&
        contentItems?.map((item, idx) => {
          const key = `background-color-content-item-${idx}`;
          const { content } = item;
          return (
            <BgColorContainer key={key} {...backgroundColorProps}>
              <BgColorSpacingContainer {...backgroundColorProps}>
                <BackgroundColorContent
                  contentItemIndex={contentItemIndex}
                  {...content}
                />
              </BgColorSpacingContainer>
            </BgColorContainer>
          );
        })}
    </div>
  );
};

export default BackgroundColor;

BackgroundColor.propTypes = {
  contentItems: PropTypes.array,
  backgroundColorProps: PropTypes.object,
  contentItemIndex: PropTypes.number
};
