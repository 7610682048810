const defaultTheme = {
  tabTitles: {
    themeName: "headline4"
  },
  tabContent: {
    themeName: "text"
  },
  accordionTheme: {
    accordionTitle: {
      typography: {
        fontFamily: "Beatrice Regular",
        fontSize: 18,

        fontWeight: "normal",
        letterSpacing: 2
      }
    },
    accordionSummary: {
      typography: {
        fontFamily: "Beatrice Regular",
        fontSize: 14
      }
    },
    spacing: {
      verticalSpacing: 20,
      horizontalSpacing: 0
    },
    borderOptions: {
      borderThickness: 1
    },
    icons: {
      expandIcon: "CaretUp",
      collapseIcon: "CaretDown"
    },
    themeName: "New Accordion Theme"
  }
};

export default defaultTheme;
