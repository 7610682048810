import { gql } from "@apollo/client";

const buildGetShippingRatesQuery = () => {
  return gql`
    query ShippingRates($postalCode: String) {
      getShippingRates(postalCode: $postalCode) {
        shipAddress {
          stateProvince
          postalCode
          countryCode
        }
        shippingRates {
          description
          rate
          rateTableId
          rule
        }
      }
    }
  `;
};

export default buildGetShippingRatesQuery;
