import apolloClient from "../client";
import buildGetShippingRatesQuery from "../queries/buildGetShippingRatesQuery";

const fetchShippingRates = async (postalCode) => {
  if (!apolloClient) return;
  const GET_SHIPPING_RATES = buildGetShippingRatesQuery();

  const response = await apolloClient.query({
    query: GET_SHIPPING_RATES,
    errorPolicy: "none",
    variables: {
      postalCode
    },
    context: { clientName: "endpoint2" }
  });

  return response?.data?.getShippingRates;
};

export default fetchShippingRates;
