import { Breakpoints } from "../../../constants/constants";

const getBackgroundColorMobileStyles = (sizeMobile) => {
  let styles = `
  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    margin-left: 8px;
    margin-right: 8px;
}
  
   `;
  if (sizeMobile !== "Full Width") {
    styles = `
    @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
        margin-left: 16px;
        margin-right: 16px;
    }
     `;
  }

  return styles;
};

export default getBackgroundColorMobileStyles;
