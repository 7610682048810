import { Breakpoints } from "../../../constants/constants";

const BACKGROUND_STYLES = {
  "Full Width": `
    margin-left: 0;
    margin-right: 0;
  `,
  "Full Width with Margin": `
    @media screen and (min-width: ${Breakpoints.IPad}) {
        margin-left: 24px;
        margin-right: 24px;
    }
  `,
  "Wide Margin": `
    @media screen and (min-width: ${Breakpoints.IPad}) {
        margin-left: 24px;
        margin-right: 24px;
    }

    @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
        margin-left: 109px;
        margin-right: 109px;
    }

    @media screen and (min-width: ${Breakpoints.DesktopLarge}) {
        margin-left: 144px;
        margin-right: 144px;
    }
  `
};

const DEFAULT_STYLE = `
  margin-left: 0;
  margin-right: 0;
`;

const getBackgroundColorDesktopStyles = (sizeDesktop) => {
  return BACKGROUND_STYLES[sizeDesktop] || DEFAULT_STYLE;
};

export default getBackgroundColorDesktopStyles;
