import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const LoadingContainer = styled.div`
  margin: 10px 0 20px;
  > span {
    justify-content: center;
  }
`;
