import React from "react";
import PropTypes from "prop-types";
import Text from "@serenaandlily/components/Text";
import defaultTheme from "./FRSCalculatorTableTheme";
import useComponentTheme from "../Theme/useComponentTheme";
import {
  TableContainer,
  Table,
  TableData,
  TableHead,
  TableHeader,
  TableRow
} from "./FRSCalculatorTableStyled";
import { COUNTRIES_MAP } from "../ApplePay/utils/getBillingAddress";
import { formatAsCurrency } from "@serenaandlily/utils";

const FRSCalculatorTable = ({
  shipAddress,
  deliveryRates,
  theme = defaultTheme
}) => {
  const FRSCalculatorTableTheme = useComponentTheme({ theme });
  return (
    <React.Fragment>
      <Text
        copy={`Results for ${shipAddress?.postalCode} Zip Code - ${
          shipAddress?.stateProvince
        }, ${COUNTRIES_MAP[shipAddress?.countryCode]}`}
        themeName={FRSCalculatorTableTheme?.lookupDescription?.themeName}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {deliveryRates.map((rate, index) => (
                <TableHeader key={index}>{rate.description}</TableHeader>
              ))}
            </TableRow>
          </TableHead>
          <tbody>
            <TableRow>
              {deliveryRates.map((rate, index) => (
                <TableData key={index}>{formatAsCurrency(rate.rate)}</TableData>
              ))}
            </TableRow>
          </tbody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

FRSCalculatorTable.propTypes = {
  shipAddress: PropTypes.object,
  deliveryRates: PropTypes.array
};

export default FRSCalculatorTable;
