import { useState } from "react";
import fetchShippingRates from "@serenaandlily/gql/utils/fetchShippingRates";
import { excludedRateIDs } from "@serenaandlily/components/FRSCalculator/constants";

export const order = [
  "USPS_SWATCH",
  "FEDEX_SMALL",
  "FEDEX_EXPEDITED",
  "FEDEX_MEDIUM",
  "FEDEX_LARGE",
  "THRESHOLD",
  "DOORSTEP",
  "ROOM_OF_CHOICE",
  "WHITE_GLOVE",
  "DELUXE",
  "CANADA_DELIVERY" // We don't have this one yet
];

const useShippingRates = () => {
  const [shippingRates, setShippingRates] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async (postalCode) => {
    setLoading(true);
    try {
      const response = await fetchShippingRates(postalCode);

      const rates = response?.shippingRates.sort((a, b) => {
        return order.indexOf(a.rateTableId) - order.indexOf(b.rateTableId);
      });

      const ratesWithoutExcluded = rates.filter(
        (rate) => !excludedRateIDs.includes(rate.rateTableId)
      );

      setShippingRates({
        shipAddress: response.shipAddress,
        shippingRates: ratesWithoutExcluded
      });
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { shippingRates, loading, error, fetchData };
};

export default useShippingRates;
