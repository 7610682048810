import { Colors, Typography } from "@serenaandlily/constants/constants";
import styled from "styled-components";

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  margin: 10px 0 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 15px;
  font-family: ${Typography.BeatriceLight};
  min-width: 400px;
  border: 2px solid ${Colors.Midnight};
`;

export const TableHead = styled.thead`
  background-color: ${Colors.Midnight};
  color: ${Colors.White};
  text-align: left;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid ${Colors.Gray};
`;

export const TableHeader = styled.th`
  padding: 12px 15px;
  border-right: 1px solid ${Colors.Gray};
  &:last-child {
    border-right: none;
  }
`;

export const TableData = styled.td`
  padding: 12px 15px;
  border-right: 1px solid ${Colors.Gray};
  &:last-child {
    border-right: none;
  }
`;
