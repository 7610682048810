const buildAccordionData = (tabContentItems, isMobile) => {
  if (!isMobile) return null;

  const accordionItems = [];

  for (const tabContentItem of tabContentItems) {
    const accordionData = {};
    const tabTitle = { text: { copy: tabContentItem?.title } };
    const tabContent = { richText: { richText: tabContentItem?.richText } };
    accordionData["accordionTitle"] = tabTitle;
    accordionData["accordionSummary"] = tabContent;
    accordionItems.push(accordionData);
  }

  return accordionItems;
};

export default buildAccordionData;
