import styled from "styled-components";
import { css } from "styled-components";
import { Breakpoints } from "../../constants/constants";

export const SliderContainer = styled.div`
  ${({ marginDesktop, marginMobileStyles }) => css`
    @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
      margin: ${marginDesktop};
    }

    @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
      padding: 0;
    }

    ${marginMobileStyles}

    // EmblaSlide
    > div > div > div > div {
      ${(props) => {
        switch (props.slideAnimation) {
          case "Fade":
            return `
            opacity: 0;
            transition: opacity 1.5s;

            &.embla-slide-fade-active {
              opacity: 1;
              transition: opacity 1.5s; 
            }
            `;
          case "Slide":
            return `
            opacity: 1;
            `;
          default:
            return `
            opacity: 1;
            `;
        }
      }}
    }
  `}
`;
