const defaultTheme = {
    themeName: "default",
    accordionTitle: {
      typography: "headline5"
    },
    accordionSummary: {
      typography: "textSmall",
      marginBottom: 20
    },
    spacing: {
      verticalSpacing: 9,
      horizontalSpacing: 0
    },
    borderOptions: {
      borderThickness: 1,
      borderColor: "Midnight 40%"
    },
    icons: {
      expandIcon: "CaretDownLight",
      collapseIcon: "CaretUpLight"
    }
};

export default defaultTheme;
