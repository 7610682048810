import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  TabComponentWrapper,
  TabDesktopContainer,
  TabDesktopHeader,
  TabNav,
  Tab,
  TabIndicator,
  TabSection,
  SectionContent
} from "./TabComponentStyled";
import { useBreakpoint } from "../Breakpoints";
import Text from "../Text";
import Accordion from "../Accordion";
import buildAccordionData from "./utils/buildAccordionData";
import defaultTheme from "./TabComponentTheme";
import RichText from "../RichText";
import useComponentTheme from "../Theme/useComponentTheme";

// eslint-disable-next-line max-lines-per-function
const TabComponent = ({ tabContentItems, theme = defaultTheme }) => {
  const [currentIdx, setCurrentIdx] = useState(0);
  const [currentLeftOffset, setCurrentLeftOffset] = useState(0);
  const [currentWidth, setCurrentWidth] = useState(0);
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["DesktopMobile"];
  const tabComponentTheme = useComponentTheme({ theme });

  const accordionItems = buildAccordionData(tabContentItems, isMobile);

  const handleTabSwitch = (e, idx) => {
    e.preventDefault();
    const currentTabLeft = document
      .querySelector(`.tab-${idx}`)
      .getBoundingClientRect().left;
    const tabNavLeft = document
      .querySelector(".tab-nav")
      .getBoundingClientRect().left;

    const left = currentTabLeft - tabNavLeft;

    setCurrentLeftOffset(left);
    setCurrentIdx(idx);
  };

  useEffect(() => {
    const currentTab = document.querySelector(`.tab-${currentIdx}`);
    if (currentTab) {
      setCurrentWidth(currentTab?.getBoundingClientRect().width);
    }

    const setWidthOnLoad = () => {
      const currentTab = document.querySelector(`.tab-${currentIdx}`);
      if (currentTab) {
        setCurrentWidth(currentTab?.getBoundingClientRect().width);
      }
    };

    window.addEventListener("load", setWidthOnLoad);

    return () => {
      window.removeEventListener("load", setWidthOnLoad);
    };
  }, [currentIdx]);

  return (
    <TabComponentWrapper>
      {isMobile && (
        <React.Fragment>
          {accordionItems && (
            <Accordion
              accordionItems={accordionItems}
              setTabIdx={setCurrentIdx}
              theme={tabComponentTheme?.accordionTheme}
            />
          )}
        </React.Fragment>
      )}
      {!isMobile && (
        <TabDesktopContainer>
          <TabDesktopHeader>
            <TabNav className="tab-nav">
              {tabContentItems.map((tabContentItem, idx) => {
                const isActive = idx === currentIdx;
                const key = `tab-${idx}`;

                return (
                  <Tab
                    key={key}
                    isActive={isActive}
                    className={`tab-${idx}`}
                    onClick={(e) => {
                      handleTabSwitch(e, idx);
                    }}
                  >
                    <Text
                      copy={tabContentItem?.title}
                      marginBottom={16}
                      themeName={tabComponentTheme?.tabTitles?.themeName}
                    />
                  </Tab>
                );
              })}
              <TabIndicator
                currentWidth={currentWidth}
                left={currentLeftOffset}
              />
            </TabNav>
          </TabDesktopHeader>
          <TabSection>
            {tabContentItems.map((tabContentItem, idx) => {
              const isActive = idx === currentIdx;
              const key = `tab-content-${idx}`;

              return (
                <SectionContent
                  key={key}
                  isActive={isActive}
                  currentIdx={currentIdx}
                >
                  <RichText
                    richText={tabContentItem?.richText}
                    themeName={tabComponentTheme?.tabContent?.themeName}
                  />
                </SectionContent>
              );
            })}
          </TabSection>
        </TabDesktopContainer>
      )}
    </TabComponentWrapper>
  );
};

export default TabComponent;

TabComponent.propTypes = {
  tabContentItems: PropTypes.object,
  theme: PropTypes.object
};
