import React from "react";
import PropTypes from "prop-types";
import Headline from "@serenaandlily/components/Headline";
import { TextInput } from "../FormComponents";
import {
  POSTAL_CODE_EMPTY,
  POSTAL_CODE_INVALID
} from "@serenaandlily/constants/formErrors";
import { validateZipCode } from "@serenaandlily/utils/validateZipCode";
import { useForm } from "react-hook-form";
import CTA from "../CTA";
import { motion } from "framer-motion";
import { Form, LoadingContainer } from "./FRSCalculatorStyled";
import defaultTheme from "./FRSCalculatorTheme";
import FRSCalculatorTable from "../FRSCalculatorTable";
import useShippingRates from "@serenaandlily/hooks/useShippingRates";
import { CircularLoader } from "../Icons/Variants";
import Text from "../Text";
import useUser from "@serenaandlily/hooks/useUser";

const FRSCalculator = ({ headline, theme = defaultTheme }) => {
  const { shippingRates, loading, fetchData } = useShippingRates();
  const { user: { data: { isDSO } = {} } = {} } = useUser();

  const handleOnSubmit = (data, e) => {
    e.preventDefault();
    fetchData(data.postalCode);
  };

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    mode: "onBlur"
  });

  if (!isDSO) return;
  return (
    <React.Fragment>
      <Headline {...headline?.headline} />
      <Form onSubmit={handleSubmit(handleOnSubmit)}>
        <TextInput
          {...register("postalCode", {
            required: POSTAL_CODE_EMPTY,
            validate: {
              validPostalCode: (value) =>
                validateZipCode(value) || POSTAL_CODE_INVALID
            }
          })}
          disabled={loading}
          error={errors?.postalCode?.message}
          id={`FRSPostalCode`}
          label="Postal Code"
          maxLength={10}
        />
        <CTA
          cta={{
            ctaText: "LOOKUP"
          }}
          buttonType="submit"
          useButtonTag={true}
          disabled={loading}
          themeName={theme?.lookupButton?.themeName}
          {...theme?.lookupButton}
          elementName="cta-email-estimate"
        />
      </Form>
      {shippingRates?.shippingRates?.length > 0 && !loading && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <FRSCalculatorTable
            shipAddress={shippingRates?.shipAddress}
            deliveryRates={shippingRates?.shippingRates}
          />
        </motion.div>
      )}
      {shippingRates?.shippingRates?.length === 0 && !loading && (
        <Text
          copy={`There are no results for ZIP Code ${shippingRates?.shipAddress?.postalCode}`}
          themeName={theme?.noResultsText?.themeName}
        />
      )}
      {loading && (
        <LoadingContainer>
          <CircularLoader />
        </LoadingContainer>
      )}
    </React.Fragment>
  );
};

FRSCalculator.propTypes = {
  headline: PropTypes.object
};

export default FRSCalculator;
