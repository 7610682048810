import React from "react";
import getContentFromSchema from "../../utils/getContentFromSchema/getContentFromSchema";
import Accordion from "../Accordion";
import Banner from "../Banner";
import Carousel from "../Carousel";
import CTA from "../CTA";
import Headline from "../Headline";
import ImageAndCaptionTile from "../ImageAndCaptionTile";
import InspirationTile from "../InspirationTile";
import ModularGrid from "../ModularGrid";
import RecommendationCarousel from "../RecommendationCarousel";
import Slider from "../Slider";
import TabComponent from "../TabComponent";
import FRSCalculator from "../FRSCalculator";

const BackgroundColorContent = (content) => {
  const { _meta: meta } = content;
  const { schema } = meta;
  const contentType = getContentFromSchema(schema);

  switch (contentType) {
    case "Accordion":
      return <Accordion {...content} />;
    case "Banner":
      return <Banner {...content} />;
    case "Carousel":
      return <Carousel {...content} />;
    case "CTA":
      return <CTA {...content} />;
    case "FRSCalculator":
      return <FRSCalculator {...content} />;
    case "Headline":
      return <Headline {...content} />;
    case "ImageAndCaptionTile":
      return <ImageAndCaptionTile {...content} />;
    case "InspirationTile":
      return <InspirationTile {...content} />;
    case "ModularGrid":
      return <ModularGrid {...content} />;
    case "RecommendationCarousel":
      return <RecommendationCarousel {...content} />;
    case "Slider":
      return <Slider {...content} />;
    case "TabComponent":
      return <TabComponent {...content} />;
    default:
      return null;
  }
};

export default BackgroundColorContent;
