const defaultTheme = {
  lookupButton: {
    themeName: "Button B",
    height: "48px"
  },
  noResultsText: {
    themeName: "text"
  }
};

export default defaultTheme;
