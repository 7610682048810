import {
  AccordionItemContainer,
  AccordionWrapper
} from "../Accordion/AccordionStyled";
import { ColumnContentContainer } from "../ModularColumn/ModularColumnStyled";
import { Colors, Breakpoints } from "../../constants/constants";
import styled from "styled-components";

export const TabComponentWrapper = styled.div`
  padding: 30px 16px;

  @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
    padding: 60px 30px;
  }

  & ${ColumnContentContainer} {
    padding: 0;
  }

  & ${AccordionWrapper} {
    border: none;

    > div {
      border-top: none;
    }

    > div:last-of-type,
    div:first-of-type {
      border: none;
    }

    & ${AccordionItemContainer} {
      border-top: 1px solid ${Colors["Midnight 40%"]};
    }
  }
`;

export const TabDesktopContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

export const TabDesktopHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabNav = styled.div`
  block-size: 100%;
  display: flex;
  border-bottom: 1px solid ${Colors["Midnight 40%"]};
  position: relative;
`;

export const Tab = styled.a`
  margin-right: 32px;
  cursor: pointer;
`;

export const TabIndicator = styled.span`
  width: ${({ currentWidth }) => (currentWidth ? `${currentWidth}px` : "")};
  height: 2px;
  position: absolute;
  background-color: ${Colors.Midnight};
  left: 0;
  bottom: 0;
  transform: ${({ left }) => (left ? `translateX(${left}px)` : "")};
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const TabSection = styled.div`
  display: flex;
  overflow: hidden;
`;

export const SectionContent = styled.div`
  align-self: flex-start;
  padding-top: 30px;
  width: 100%;
  min-width: 100%;
  transform: ${({ currentIdx }) => `translateX(${currentIdx * -100}%)`};
  height: ${({ isActive }) => (isActive ? "auto" : "0")};
  /* display: ${({ isActive }) => (isActive ? "block" : "none")}; */
  /* opacity: ${({ isActive }) => (isActive ? "1" : "0")}; */
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;
