import React from "react";
import PropTypes from "prop-types";
import LinksSection from "../LinksSection";
import getContentFromSchema from "../../utils/getContentFromSchema";
import ModularGrid from "../ModularGrid";

const AccordionSummaryContent = ({ accordionSummaryContent }) => {
  const ContentTypes = {
    LinksSection,
    ModularGrid
  };

  const contentType = getContentFromSchema(
    accordionSummaryContent?._meta?.schema
  );
  const Content = ContentTypes[contentType];
  const contentProps =
    contentType === "LinksSection"
      ? { ctas: accordionSummaryContent?.ctas }
      : accordionSummaryContent;

  if (Content) return <Content {...contentProps} />;
  return null;
};

export default AccordionSummaryContent;

AccordionSummaryContent.propTypes = {
  accordionSummaryContent: PropTypes.object
};
