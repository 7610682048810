import React, { useRef, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "styled-components";
import {
  AccordionItemContainer,
  AccordionTitleContainer,
  AccordionTitle,
  AccordionIconContainer,
  AccordionDetail
} from "./AccordionStyled";
import { useBreakpoint } from "../Breakpoints";
import RichText from "../RichText";
import Icons from "../Icons";
import Text from "../Text";
import AccordionSummaryContent from "./AccordionSummaryContent";

const AccordionItem = ({
  accordionTitle,
  accordionSummary,
  accordionSummaryContent,
  isOpen,
  idx,
  toggleItem,
  clearButton,
  content,
  scrollOnExpand
}) => {
  const DESKTOP_MENU_HEIGHT = 65;
  const RESPONSIVE_MENU_HEIGHT = 65;
  const [scrollHeight] = useState("");
  const itemRef = useRef(null);

  // Get values from the theme
  const {
    accordionTitle: accordionTitleTheme,
    accordionSummary: accordionSummaryTheme,
    icons
  } = useTheme();

  const { expandIcon, collapseIcon, iconWidth, iconHeight } = icons || {};
  const accordionTitleProps = {
    theme: accordionTitleTheme,
    ...accordionTitle?.text
  };

  const accordionSummaryProps = {
    theme: accordionSummaryTheme,
    ...accordionSummary?.richText
  };

  const breakpoints = useBreakpoint();
  const isDesktop = breakpoints["ScreenWidth"];

  const iconType = isOpen ? collapseIcon || "Minus" : expandIcon || "Plus";

  const detailsRef = useRef();
  const [isAnimating, setIsAnimating] = useState(false);

  const handleToggle = useCallback(() => {
    if (!isAnimating) {
      setIsAnimating(true);
      toggleItem(idx);
      setTimeout(() => {
        if (!isOpen) {
          const fixedHeaderHeight = isDesktop
            ? RESPONSIVE_MENU_HEIGHT
            : DESKTOP_MENU_HEIGHT;
          const elementPosition =
            itemRef.current.getBoundingClientRect().top + window.pageYOffset;
          if (scrollOnExpand) {
            window.scrollTo({
              top: elementPosition - fixedHeaderHeight,
              behavior: "smooth"
            });
          }
        }
        setIsAnimating(false);
      }, 300);
    }
  }, [idx, isOpen, isAnimating, isDesktop]);

  return (
    <AccordionItemContainer data-testid="accordion-container">
      <AccordionTitleContainer
        onClick={handleToggle}
        ref={itemRef}
        data-testid="accordion-item"
      >
        <AccordionTitle>
          <Text {...accordionTitleProps} />
        </AccordionTitle>
        {clearButton}
        <AccordionIconContainer>
          <Icons type={iconType} width={iconWidth} height={iconHeight} />
        </AccordionIconContainer>
      </AccordionTitleContainer>
      <AccordionDetail
        ref={detailsRef}
        isOpen={isOpen}
        scrollHeight={scrollHeight}
      >
        {accordionSummary && <RichText {...accordionSummaryProps} />}
        {accordionSummaryContent && (
          <AccordionSummaryContent
            accordionSummaryContent={accordionSummaryContent}
            data-testid="accordion-content"
          />
        )}
        {content}
      </AccordionDetail>
    </AccordionItemContainer>
  );
};

export default AccordionItem;

AccordionItem.propTypes = {
  accordionTitle: PropTypes.object,
  accordionSummary: PropTypes.object,
  canOpenMultiple: PropTypes.bool,
  idx: PropTypes.number,
  clearButton: PropTypes.object,
  content: PropTypes.any,
  accordionConfigs: PropTypes.object,
  accordionSummaryContent: PropTypes.object,
  setTabIdx: PropTypes.func
};
