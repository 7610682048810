import React from "react";
import PropTypes from "prop-types";
import { SliderContainer } from "./SliderStyled";
import GenericCarousel from "../GenericCarousel";
import { getDesktopMarginValue } from "../../utils/getMarginValue";
import { getMobileCarouselMargins } from "../Carousel/utils/getMobileCarouselMargins";

const Slider = ({ contentItems, sliderConfigs }) => {
  const { slideAnimation, desktopSize, mobileSize } = sliderConfigs;

  const marginDesktop = getDesktopMarginValue(desktopSize || "Full Width");
  const marginMobileStyles = getMobileCarouselMargins(mobileSize);

  return (
    <SliderContainer
      slideAnimation={slideAnimation}
      marginDesktop={marginDesktop}
      marginMobileStyles={marginMobileStyles}
    >
      <GenericCarousel
        carouselContent={contentItems}
        carouselConfigs={sliderConfigs}
      />
    </SliderContainer>
  );
};

export default Slider;

Slider.propTypes = {
  contentItems: PropTypes.array,
  sliderConfigs: PropTypes.object
};
