export const ADDRESS_LINE_1_EMPTY = "Please enter an address.";
export const CARD_NUMBER_INVALID =
  "Please enter the number as it appears on your card.";
export const CITY_EMPTY = "Please enter a city.";
export const CONFIRM_EMAIL_EMPTY = "Please confirm you email address.";
export const CONFIRM_EMAIL_INVALID =
  "It seems that this email address is invalid. Please try again.";
export const CONFIRM_EMAIL_MATCH =
  "Uh-oh. Your emails do not match. Please check your spelling and try again.";
export const CONFIRM_PASSWORD_EMPTY = "Please confirm your new password.";
export const CONFIRM_PASSWORD_MATCH =
  "Uh-oh. Your passwords do not match. Please check your spelling and try again.";
export const COUNTRY_EMPTY = "Please enter a country.";
export const CUSTOMER_SEARCH_EMPTY =
  "Please enter a valid email, trade member or phone number.";
export const DESIGN_SERVICE_EMPTY = "Please select a design service.";
export const DNSU_EMPTY = "Please specify a valid date.";
export const EMAIL_EMPTY = "Please enter a valid email address.";
export const EMAIL_INVALID =
  "It seems that this email address is invalid. Please try again.";
export const ESTIMATE_NAME_EMPTY = "Please enter an estimate name.";
export const ESTIMATE_COMPANY_NAME_EMPTY = "Please enter a company name.";
export const EXPIRE_MONTH_EMPTY = "Please select an expiration month.";
export const EXPIRE_YEAR_EMPTY = "Please select an expiration year.";
export const EXPIRE_DATE_EMPTY = "Please enter a valid expiration date.";
export const EXPIRE_DATE_EXPIRED = "Your card is expired.";
export const INVALID_DATE_MONTH = "Please enter a valid month.";
export const NAME_EMPTY = "Please enter a name.";
export const FIRST_NAME_EMPTY = "Please enter a first name.";
export const FULL_NAME_EMPTY =
  "Please enter your name as it appears on the card.";
export const LAST_NAME_EMPTY = "Please enter a last name.";
export const NICKNAME_EMPTY = "Please enter an address nickname.";
export const PASSWORD_EMPTY = "Please enter your new password.";
export const PASSWORD_INVALID =
  "Oops! Password is not strong enough. Please make sure to enter a minimum of 8 characters containing upper and lower case letters, numbers and the following special characters: $ ! ? - * # % ";
export const PHONE_EMPTY = "Please enter a telephone number.";
export const PHONE_INVALID = "Please specify a valid phone number.";
export const POSTAL_CODE_EMPTY = "Please enter a postal code.";
export const POSTAL_CODE_INVALID = "Sorry that postal code is invalid.";
export const SECURITY_CODE_INVALID = "Please enter a valid security code.";
export const STATE_PROVINCE_EMPTY = "Please enter a state.";
export const ZIP_CODE_EMPTY = "Please enter a zip code.";
export const ZIP_CODE_INVALID = "Sorry that zip code is invalid.";
export const CHECK_NUMBER_INVALID =
  "Please enter a valid numeric check number.";
export const BIRTHDAY_MONTH_REQUIRED = "Please select a birthday.";
export const INVALID_SUBMISSION =
  "Your request could not be processed, please Contact Customer Care at customercare@serenaandlily.com or 866.597.2742";
export const GIFT_MESSAGE_EMPTY = "Please enter gift message";
